<template>
  <section :class="sectionClass">
    <header class="jumbotron jumbotron-fluid home-title">
      <div v-if="showText" class="container-fluid text-center">
        <h1 class="display-3 text-white">Welcome to Michael's LyfeScheme!</h1>
        <p class="lead pb-4 text-white">
          <i>
            I help men & women over 40, get strong, eat healthy, change their
            mindset, and live a superior life of health and wellness.
          </i>
        </p>
        <p>
          <router-link
            to="/programs"
            class="btn btn-dark btn-gradient shadow btn-lg text-white"
            type="button"
            >Learn More</router-link
          >
        </p>
      </div>
      <div v-if="!showText" class="row mt-5">
        <h1 class="display-3 mt-5 text-white">{{ headerText }}</h1>
      </div>
    </header>
  </section>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: {
    sectionClass: {
      required: true,
      type: String,
    },
    showText: {
      required: true,
      type: Boolean,
      default: false,
    },
    headerText: {
      required: false,
      type: String,
      default: null,
    },
  },
};
</script>
