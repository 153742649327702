<template>
  <NavbarComponent :is-mobile="mobileMode" />
  <router-view />
  <FooterComponent />
</template>

<script>
import NavbarComponent from "./components/core/Navbar.vue";
import FooterComponent from "./components/core/Footer.vue";
import { Collapse } from "bootstrap";

export default {
  name: "App",
  components: {
    NavbarComponent,
    FooterComponent,
  },
  data() {
    return {
      mobileMode: false,
    };
  },
  mounted() {
    const navMenu = document.querySelector(".nav-menu");

    if (this.isMobile()) {
      navMenu.classList.add("navbar-custom");
      navMenu.classList.add("shadow");
      this.mobileMode = true;
    }

    document.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        navMenu.classList.add("navbar-custom");
        navMenu.classList.add("shadow");
      } else {
        navMenu.classList.remove("navbar-custom");
        navMenu.classList.remove("shadow");
      }
    });

    // close mobile navigation on link click, delay by 200ms
    const navLinks = document.querySelectorAll(".nav-item");
    const menuToggle = document.getElementById("navBarLinks");
    const bsCollapse = new Collapse(menuToggle, { toggle: false });
    navLinks.forEach((l) => {
      l.addEventListener("click", () => {
        if (this.isMobile()) {
          setTimeout(() => {
            bsCollapse.toggle();
          }, 200);
        }
      });
    });

    // add recaptcha
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?render=6LfKCRonAAAAAHjqlVDlBFnPyy0lx21aSkB0eDBU"
    );
    this.recaptchaElement = document.head.appendChild(recaptchaScript);
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.mobileMode = true;
        return true;
      } else {
        this.mobileMode = false;
        return false;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>
