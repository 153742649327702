import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Contact from "./components/Contact.vue";
import NotFound from "./components/NotFound.vue";
import Bio from "./components/Bio.vue";
import Programs from "./components/Programs.vue";
import Testimonials from "./components/Testimonials.vue";
import Vision from "./components/Vision.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Michael's LyfeScheme - Health and Wellness Coach - Home",
      metaTags: [
        {
          name: "description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "og:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "author",
          content: "Patent Pending Studios",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "googlebot",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "Michael's LyfeScheme, personal trainer, life coach, health and wellness, over 40, San Jose, California",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content: "Michael's LyfeScheme - Health and Wellness Coach - Home",
        },
        {
          property: "og:image",
          content: "https://lyfescheme.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://lyfescheme.com",
        },
        {
          property: "og:site_name",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
      ],
    },
  },
  {
    path: "/bio",
    name: "Bio",
    component: Bio,
    meta: {
      title: "Michael's LyfeScheme - Health and Wellness Coach - Bio",
      metaTags: [
        {
          name: "description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "og:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "author",
          content: "Patent Pending Studios",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "googlebot",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "Michael's LyfeScheme, personal trainer, life coach, health and wellness, over 40, San Jose, California",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content: "Michael's LyfeScheme - Health and Wellness Coach - Bio",
        },
        {
          property: "og:image",
          content: "https://lyfescheme.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://lyfescheme.com",
        },
        {
          property: "og:site_name",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
      ],
    },
  },
  {
    path: "/vision",
    name: "Vision",
    component: Vision,
    meta: {
      title: "Michael's LyfeScheme - Health and Wellness Coach - My Vision",
      metaTags: [
        {
          name: "description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "og:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "author",
          content: "Patent Pending Studios",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "googlebot",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "Michael's LyfeScheme, personal trainer, life coach, health and wellness, over 40, San Jose, California",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content:
            "Michael's LyfeScheme - Health and Wellness Coach - My Vision",
        },
        {
          property: "og:image",
          content: "https://lyfescheme.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://lyfescheme.com",
        },
        {
          property: "og:site_name",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
      ],
    },
  },
  {
    path: "/programs",
    name: "Programs",
    component: Programs,
    meta: {
      title:
        "Michael's LyfeScheme - Health and Wellness Coach - Program Details",
      metaTags: [
        {
          name: "description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "og:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "author",
          content: "Patent Pending Studios",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "googlebot",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "Michael's LyfeScheme, personal trainer, life coach, health and wellness, over 40, San Jose, California",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content:
            "Michael's LyfeScheme - Health and Wellness Coach - Program Details",
        },
        {
          property: "og:image",
          content: "https://lyfescheme.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://lyfescheme.com",
        },
        {
          property: "og:site_name",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
      ],
    },
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    component: Testimonials,
    meta: {
      title: "Michael's LyfeScheme - Health and Wellness Coach - Testimonials",
      metaTags: [
        {
          name: "description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "og:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "author",
          content: "Patent Pending Studios",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "googlebot",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "Michael's LyfeScheme, personal trainer, life coach, health and wellness, over 40, San Jose, California",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content:
            "Michael's LyfeScheme - Health and Wellness Coach - Testimonials",
        },
        {
          property: "og:image",
          content: "https://lyfescheme.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://lyfescheme.com",
        },
        {
          property: "og:site_name",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Michael's LyfeScheme - Health and Wellness Coach - Contact Me",
      metaTags: [
        {
          name: "description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "og:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "author",
          content: "Patent Pending Studios",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "googlebot",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "Michael's LyfeScheme, personal trainer, life coach, health and wellness, over 40, San Jose, California",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content:
            "Michael's LyfeScheme - Health and Wellness Coach - Contact Me",
        },
        {
          property: "og:image",
          content: "https://lyfescheme.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://lyfescheme.com",
        },
        {
          property: "og:site_name",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
      ],
    },
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: {
      title:
        "Michael's LyfeScheme - Health and Wellness Coach - 404 - Not Found",
      metaTags: [
        {
          name: "description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "og:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
        {
          property: "author",
          content: "Patent Pending Studios",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "googlebot",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "Michael's LyfeScheme, personal trainer, life coach, health and wellness, over 40, San Jose, California",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content:
            "Michael's LyfeScheme - Health and Wellness Coach - 404 - Not Found",
        },
        {
          property: "og:image",
          content: "https://lyfescheme.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://lyfescheme.com",
        },
        {
          property: "og:site_name",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Michael's LyfeScheme - Health and Wellness Coach",
        },
        {
          property: "twitter:description",
          content:
            "Transform your life with Michael's LyfeScheme, a personal trainer and life coach specializing in health and wellness for men and women over 40 in San Jose, California. Discover the 4 pillar method to achieve your fitness and life goals.",
        },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // routes to another page via anchor link if it exists
  scrollBehavior(to, from, savedPosition) {
    // If saved position is passed down, return saved position
    if (savedPosition) {
      return savedPosition;
    }

    // if hash is included, go to the element matching the anchor hash
    if (to.hash) {
      return { el: to.hash };
    }

    // Otherwise, return 0, 0
    return { top: 0, left: 0 };
  },
  // linkActiveClass: "nav-active",
  // linkExactActiveClass: "router-link-active",
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
