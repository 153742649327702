<template>
  <HeaderComponent
    :section-class="'hero-section-programs'"
    :show-text="false"
  ></HeaderComponent>
  <div id="recruiting-about" class="container">
    <div class="row flex-lg-row-reverse align-items-center pt-5">
      <div class="col-12">
        <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">
          Individualized programs for men and women 40 an over.
        </h1>
      </div>
      <div class="col-lg-12">
        <ul class="lead">
          <li>Fit Strength Program</li>
          <li>Fit Strength Program: General Fitness and Movement</li>
          <li>
            Sustainable and Delicious Food-Related Meal Planning & Habit
            Coaching Programs
          </li>
          <li>
            Supportive and Motivational Health and Wellness Coaching Programs
          </li>
          <li>Transformative Mindset Coaching Programs</li>
        </ul>
        <p>Read more:</p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          <a
            type="button"
            class="btn btn-dark text-primary"
            href="#fit-strength"
          >
            Fit Strength
          </a>
          <a
            type="button"
            class="btn btn-dark text-primary"
            href="#meal-planning"
          >
            Meal Planning
          </a>
          <a
            type="button"
            class="btn btn-dark text-primary"
            href="#motivational-health-wellness"
          >
            Health and Wellness
          </a>
          <a
            type="button"
            class="btn btn-dark text-primary"
            href="#transformative-mindset"
          >
            Transformative Mindset
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row pt-5">
      <div class="col-12">
        <h1 class="display-5 fw-bold text-body-emphasis lh-1">
          Program Details
        </h1>
      </div>
    </div>
  </div>

  <div id="fit-strength" class="container pt-5">
    <div class="row">
      <div class="col-lg-6 col-12 order-1 order-lg-0">
        <CardComponent :title="'Fit Strength Programs'">
          <p class="lead">
            Your personal training program, either in-person or virtual, either
            Fit Strength or General Fitness and Movement, is a great way to
            leverage your fitness transformation. Each program is designed and
            customized around you as an individual and your fitness goal -
            whether it is strength development and muscle gain, conditioning,
            general fitness, mobility and movement, or preparing for a
            competition - every training session is recreational, effective and
            impactful. Each workout program I design includes all of the
            necessary methods to achieve your goal and maintain a healthy and
            sustainable fitness lifestyle.
          </p>
        </CardComponent>
      </div>
      <div class="col-lg-6 col-12 order-0 order-lg-1">
        <img
          src="../assets/images/kettlebells.jpg"
          class="border border-2 border-dark mt-3 rounded shadow img-fluid"
        />
      </div>
    </div>
  </div>
  <div id="meal-planning" class="container pt-5">
    <div class="row">
      <div class="col-lg-6 col-12 order-0 order-lg-0">
        <img
          src="../assets/images/meal-planning.jpg"
          class="border border-2 border-dark mt-3 rounded shadow img-fluid"
        />
      </div>
      <div class="col-lg-6 col-12 order-1 order-lg-1">
        <CardComponent
          :title="'Sustainable and Delicious Food-Related Meal Planning & Habit Coaching Programs'"
        >
          <p class="lead">
            Your personalized nutritional habit coaching program, either
            in-person or virtual, will include a meal plan skeleton to show you
            what your healthy day of eating might look like. You have the
            freedom to make food swaps within your meal plan based on your
            program. I incorporate healthy nutritional habits, such as water
            drinking, getting more protein in your meals, healthy grocery
            shopping, and dozens of others, to help you have a different focus
            each week. I will support you and offer 6 suggestions of healthy
            habits to hold you accountable for throughout the program as this
            will insure your own success long-term.
          </p>
        </CardComponent>
      </div>
    </div>
  </div>
  <div id="motivational-health-wellness" class="container pt-5">
    <div class="row">
      <div class="col-lg-6 col-12 order-1 order-lg-0">
        <CardComponent
          :title="'Supportive and Motivational Health and Wellness Coaching Programs'"
        >
          <p class="lead">
            Make a self-directed change. Your personalized health and wellness
            coaching program, either in-person or virtual, is designed to
            support you 100% in making a sustainable, self-directed change. I
            will empower you to make incremental, doable choices and changes
            that will have a definitive impact on your overall health, movement,
            nutrition, diet, sleep, recovery, regeneration and stress management
            with a focus on holistic lifestyle choices.
          </p>
        </CardComponent>
      </div>
      <div class="col-lg-6 col-12 order-0 order-lg-1">
        <img
          src="../assets/images/motivational.jpg"
          class="border border-2 border-dark mt-3 rounded shadow img-fluid"
        />
      </div>
    </div>
  </div>
  <div id="transformative-mindset" class="container pt-5">
    <div class="row">
      <div class="col-lg-6 col-12 order-0 order-lg-0">
        <img
          src="../assets/images/mindsets.jpg"
          class="border border-2 border-dark mt-3 rounded shadow img-fluid"
        />
      </div>
      <div class="col-lg-6 col-12 order-1 order-lg-1">
        <CardComponent :title="'Transformative Mindset Coaching Programs'">
          <p class="lead">
            Find your flow state with your transformative mindset coaching
            program, either in-person or virtual. I will help you stay focused
            and on-course as we work together to set you up for success. Our
            weekly check-ins help you break through any limiting beliefs or
            anything that you feel is holding you back from taking the next step
            in your evolution. We work on developing a mindset of confidence,
            courage, discipline, gratitude, and growth. We celebrate your wins
            (and failures, as these lead to learning). I give you daily and
            weekly empowerment challenges. Together we develop a strategy to
            create new mindful habits and be consistent with them. While working
            together we will awaken your passions, find your purpose, sustain a
            growth mindset, train you for mindfulness, and prepare you to win.
          </p>
        </CardComponent>
      </div>
    </div>
  </div>
  <div class="container text-center pt-5">
    <h1 class="display-4 fw-bold text-body-emphasis">
      Ready to take the next step?
    </h1>
    <div class="col-lg-6 mx-auto">
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <router-link
          to="/contact"
          type="button"
          class="btn btn-dark btn-lg px-4 me-sm-3 text-primary"
        >
          Contact Me
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "./core/Header.vue";
import CardComponent from "./core/Card.vue";

export default {
  name: "ProgramsComponent",
  components: {
    HeaderComponent,
    CardComponent,
  },
};
</script>
