<template>
  <ModalComponent
    v-model="modalOpen"
    :value="modalOpen"
    @input="openModal(modalSrc, modalAlt)"
  >
    <div class="text-center col-12">
      <img class="w-100" :src="modalSrc" :alt="modalAlt" />
    </div>
  </ModalComponent>
  <HeaderComponent
    :section-class="'hero-section-testimonials'"
    :show-text="false"
  ></HeaderComponent>
  <div class="container">
    <div class="row pt-5">
      <div class="col-12">
        <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">
          Stories of Success
        </h1>
      </div>

      <div class="col-12">
        <CardComponent>
          <p>
            I did the 12 week program with Michael and it was amazing! After a
            call to assess my needs Michael tailored a total body workout that
            was fun and had variety, each week was different which I loved! I
            did the workouts on my own with a weekly check in from Michael to
            see how I was doing, and received fun videos during the week to keep
            me going! Michael was there whenever I had a question about an
            exercise, and even did video demos! I cannot recommend Michael
            highly enough. I now have a program that I can carry with me and
            keep the momentum going and stay on track.
          </p>
          <p class="float-end"><i>- Roxanne D</i></p>
        </CardComponent>
      </div>

      <div class="col-12">
        <CardComponent>
          <div class="row">
            <div class="col-12 col-xl-2 text-center mb-2">
              <button
                class="button-hidden"
                @click="
                  openModal(
                    'https://lyfescheme.com/static/kristip-testimonial.jpg',
                    'Kristi P'
                  )
                "
              >
                <img
                  class="img img-fluid img-thumbnail img-max-height rounded shadow"
                  src="https://lyfescheme.com/static/kristip-testimonial.jpg"
                />
              </button>
              <p class="card-text">
                (<i class="bi bi-zoom-in"></i> Click image to zoom)
              </p>
            </div>
            <div class="col-12 col-xl-10">
              <p>
                I contacted Michael because I was very frustrated with myself,
                having troubles staying motivated with exercise, and frustration
                wanting to exercise but experiencing pain and injuries after.
                Now I am motivated to exercise and to exercise regularly. I have
                an exercise library that works for my goals of what I am
                targeting and also to keep me motivated. Michael is supportive
                in all aspects of his mentoring with exercise, nutrition, and
                personal empowerment. He's taught me to prioritize ME! He's
                always available to answer questions and he's supportive of all
                of my wins like getting into a smaller pair of jeans! Because of
                Michael, my thoughts of fitness have changed positively. It's
                now a part of my every day. Michael pays attention to the
                details of his clients. Michael shows me the exercise prior to
                trying it and then mirrors me so I can learn the movement and be
                able to do it independently at home. Michael's attention to his
                clients is unparalleled.
              </p>
              <p class="float-end"><i>- Kristi P</i></p>
            </div>
          </div>
        </CardComponent>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "./core/Card.vue";
import HeaderComponent from "./core/Header.vue";
import ModalComponent from "./core/Modal.vue";

export default {
  name: "TestimonialsComponent",
  components: {
    CardComponent,
    HeaderComponent,
    ModalComponent,
  },
  data() {
    return {
      modalOpen: false,
      modalSrc: null,
      modalAlt: "alt",
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    openModal(src, alt) {
      this.modalSrc = src;
      this.modalAlt = alt;
      this.modalOpen = !this.modalOpen;
    },
  },
};
</script>
