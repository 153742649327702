<template>
  <CardComponent>
    <form>
      <div class="form-group mb-2">
        <label for="contactNameInput" class="form-label"
          >Name (First and Last)</label
        >
        <span class="text-danger">*</span>
        <input
          id="contactNameInput"
          v-model="name"
          type="text"
          class="form-control"
          placeholder="Your Name Here"
          required
          :disabled="predefinedName"
          @focusin="validateInputs(false)"
          @focusout="validateInputs(false)"
        />
      </div>
      <div class="form-group mb-2">
        <label for="contactEmailInput" class="form-label">Email Address</label>
        <span class="text-danger">*</span>
        <input
          id="contactEmailInput"
          v-model="email"
          type="email"
          class="form-control"
          placeholder="name@email.com"
          required
          :disabled="predefinedEmail"
          @blur="validateEmail"
          @focusin="validateInputs(false)"
          @focusout="validateInputs(false)"
        />
      </div>
      <div class="form-group mb-2">
        <label for="contactPhoneInput" class="form-label">Phone Number</label>
        <span class="text-danger">*</span>
        <input
          id="contactPhoneInput"
          v-model="phone"
          type="tel"
          class="form-control"
          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
          placeholder="(555) 555-5555"
          required
          :disabled="predefinedPhone"
          @blur="validatePhoneNumber"
          @focusin="validateInputs(false)"
          @focusout="validateInputs(false)"
        />
      </div>
      <div class="form-group mb-2">
        <label for="contactEmailSelect" class="form-label">Message Type</label>
        <span class="text-danger">*</span>
        <select
          id="contactEmailSelect"
          v-model="type"
          class="form-control"
          default="Select an option"
          :disabled="disabled"
          required
        >
          <option>General Contact</option>
          <option>Join Lyfescheme</option>
          <option>Testimonial</option>
        </select>
      </div>
      <div class="form-group mb-2">
        <label for="contactTextAreaInput" class="form-label"
          >Message ({{ messageLength }} out of {{ messageLimit }} characters
          used)</label
        >
        <span class="text-danger">*</span>
        <textarea
          id="contactTextAreaInput"
          v-model="message"
          placeholder="Write your message here!"
          class="form-control noresize"
          rows="8"
          required
          @input="updateMessageLength(false)"
          @blur="updateMessageLength(false)"
        ></textarea>
      </div>
      <div v-if="messageLength > messageLimit" class="form-group">
        <p class="text-danger">Your message is too long</p>
      </div>
      <div class="form-group text-center">
        <i class="bi bi-info-circle"></i>
        <small>
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a>
          apply.
        </small>
      </div>
      <div class="form-group">
        <p class="text-end">
          <i
            >* Do not send any sensitive information such as billing information
            or passwords in this form.</i
          >
        </p>
      </div>
      <div v-if="success" class="form-group">
        <div class="alert alert-success">
          {{ success }}
        </div>
      </div>
      <div v-if="error" class="form-group">
        <div class="alert alert-danger">
          {{ error }}
        </div>
      </div>
      <div class="form-group float-end">
        <button
          type="button"
          class="btn btn-danger m-2"
          @click="resetAll(true)"
        >
          Reset
        </button>
        <button
          type="button"
          :disabled="!email || !message || !type || !valid || !name || !phone"
          class="btn btn-success"
          @click="submit"
        >
          Submit
          <i
            v-if="submitting"
            class="spinner-grow spinner-grow-sm"
            role="status"
          ></i>
        </button>
      </div>
    </form>
  </CardComponent>
</template>

<script>
import CardComponent from ".././core/Card.vue";
import { EmailService } from "../../services/EmailService";

export default {
  name: "ContactFormComponent",
  components: {
    CardComponent,
  },
  props: {
    predefinedName: {
      type: String,
      required: false,
      default: null,
    },
    predefinedPhone: {
      type: String,
      required: false,
      default: null,
    },
    predefinedType: {
      type: String,
      required: false,
      default: null,
    },
    predefinedEmail: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      messageLength: 0,
      messageLimit: 512,
      submitting: false,
      success: null,
      error: null,
      email: this.predefinedEmail ?? null,
      name: this.predefinedName ?? null,
      phone: this.predefinedPhone ?? null,
      type: this.predefinedType ?? "General Contact",
      validPhone: false,
      validEmail: false,
      valid: false,
      message: null,
      recaptchElement: null,
      clear: (fromSubmit = false) => {
        this.message = null;
        this.name = this.predefinedName ?? null;
        this.email = this.predefinedEmail ?? null;
        this.phone = this.predefinedPhone ?? null;
        this.valid = false;
        this.type = this.predefinedType ?? "General Contact";
        this.updateMessageLength(fromSubmit);
        return;
      },
      updateMessageLength: (fromSubmit = false) => {
        this.messageLength = this.message ? this.message?.length : 0;

        if (!fromSubmit) {
          this.validateInputs();
        }
      },
      submit: async () => {
        // nonce for submitting
        if (this.submitting) {
          return;
        }

        // validate inputs so we dont send bad data
        if (!this.validateInputs()) {
          return;
        }

        // clear errors initially
        this.clearErrors();

        this.submitting = true;

        grecaptcha.ready(() => {
          grecaptcha
            .execute("6LfKCRonAAAAAHjqlVDlBFnPyy0lx21aSkB0eDBU", {
              action: "submit",
            })
            .then(async (token) => {
              const body = {
                Email: this.email,
                Type: this.type,
                Message: `${this.type} from ${this.name} - ${this.phone} - ${this.email} - Message: ${this.message}`,
              };

              if (token) {
                // make a request to send an email
                const res = await EmailService.sendEmail(body);

                // if success, show success message
                if (res && res.status == 204) {
                  this.success =
                    "Thank you! Your message has been received. If your message requires a response, I will get back to you as soon as possible.";
                  this.submitting = false;

                  // the logic flow here is deliberate - error must be set to null after clearing
                  this.clear(false);
                  this.error = null;
                } else {
                  this.success = null;
                  this.submitting = false;
                  this.error = `Mail could not be sent. Please try again later.`;
                }
              } else {
                this.success = null;
                this.submitting = false;
                this.error = `Mail could not be sent due to captcha fail. Please try again later.`;
              }
            });
        });
      },
      clearErrors: () => {
        this.error = null;
        this.success = null;
      },
      validateEmail: () => {
        this.validEmail = true;
        // eslint-disable-next-line no-useless-escape
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (this.email && !regex.test(this.email)) {
          this.error = "Please enter a valid email address";
          this.validEmail = false;
          return false;
        } else {
          this.validEmail = true;
          this.error = null;
          return true;
        }
      },
      validatePhoneNumber: () => {
        this.validPhone = true;

        // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
        const regex = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im;

        if (this.phone && !regex.test(this.phone)) {
          this.error = "Please enter a valid phone number";
          this.validPhone = false;
        } else {
          this.validPhone = true;
          this.error = null;
          return true;
        }
      },
      validateInputs: () => {
        this.error = null;
        this.valid = true;

        if (
          !this.email ||
          !this.type ||
          !this.message ||
          !this.phone ||
          !this.name
        ) {
          this.error = "Please fill in all required fields";
          return (this.valid = false);
        }

        if (this.message.length > 512) {
          this.error = "Message is too long";
          return (this.valid = false);
        }

        if (this.predefinedType && this.predefinedType != this.type) {
          this.error = "Type is incorrect";
          return (this.valid = false);
        }

        if (!this.validateEmail()) {
          return (this.valid = false);
        }

        if (!this.validatePhoneNumber()) {
          return (this.valid = false);
        }

        return (this.valid = true);
      },
      resetAll: (shouldClearErrors = true) => {
        this.clear();

        if (shouldClearErrors) {
          this.clearErrors();
        }
      },
    };
  },
};
</script>
