<template>
  <ModalComponent
    v-model="modalOpen"
    :value="modalOpen"
    @input="openModal(modalSrc, modalAlt)"
  >
    <div class="text-center col-12">
      <img class="w-100" :src="modalSrc" :alt="modalAlt" />
    </div>
  </ModalComponent>
  <HeaderComponent
    :section-class="'hero-section-my-vision'"
    :show-text="false"
  />
  <div class="container">
    <div class="row align-items-center pt-5">
      <div class="col-12">
        <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">
          Bringing out the best in people with my Four-Pillar Plan.
        </h1>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-xl-8">
        <div class="card mt-3 mb-3 shadow">
          <button
            class="button-hidden"
            @click="
              openModal(
                'https://lyfescheme.com/static/lyfescheme-four-pillars.png',
                'Lyfescheme 4 Pillars'
              )
            "
          >
            <img
              class="card-img-top"
              role="img"
              aria-label="Lyfescheme 4 Pillars"
              src="@/assets/images/lyfescheme-four-pillars.png"
            />
          </button>
          <div class="card-body">
            <strong>Lyfescheme Four Pillars</strong>
            <p class="card-text">
              (<i class="bi bi-zoom-in"></i> Click image to zoom)
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-4">
        <CardComponent>
          <div class="text-center">
            <div class="px-4 mt-1">
              <p>
                Michael's LyfeScheme is a personal health & wellness lifestyle
                coaching experience for people 40 and over.
              </p>
              <p>
                I am an essential health and wellness need in the life of every
                individual seeking accountable personal development.
              </p>
              <p>
                The LyfeScheme concept coaches, inspires, encourages and
                supports. It is of my own volition to act as your partner to
                advise, strategize, implement and manifest your personal
                self-confidence and self-mastery.
              </p>
              <p>
                I help you unleash your superpowers through fitness training,
                nutrition coaching, and life & health coaching.
              </p>
              <p>
                I am here to partner with you and work together with purpose and
                intention to give you the agency to create expansive
                transformation, spirited life change, and bold self-empowerment.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <router-link to="/programs" class="btn btn-dark text-primary">
                See all programs
              </router-link>
            </div>
          </div>
        </CardComponent>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "./core/Card.vue";
import HeaderComponent from "./core/Header.vue";
import ModalComponent from "./core/Modal.vue";

export default {
  name: "VisionComponent",
  components: {
    CardComponent,
    HeaderComponent,
    ModalComponent,
  },
  data() {
    return {
      modalOpen: false,
      modalSrc: null,
      modalAlt: "alt",
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    openModal(src, alt) {
      this.modalSrc = src;
      this.modalAlt = alt;
      this.modalOpen = !this.modalOpen;
    },
  },
};
</script>
