<template>
  <footer class="footer sticky-sm-bottom py-3 text-center">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <span class="text-muted"
            >&copy; 2023 - {{ new Date().getFullYear() }} Michael's LyfeScheme
          </span>
        </div>
        <div class="col-12">
          <a href="https://instagram.com/mjplyfe?igshid=YTQwZjQ0NmI0OA==">
            <img class="m-2" src="@/assets/svg/instagram.svg" />
          </a>
          <a href="mailto:michael@lyfescheme.com">
            <img class="m-1" src="@/assets/svg/mail.svg" />
          </a>
        </div>
        <div class="col-12">
          <span
            >Created by
            <a class="link-dark" href="https://patentpendingstudios.com"
              >Patent Pending Studios</a
            ></span
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
