import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/styles.scss";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import router from "./router";

const app = createApp(App).use(router).mount("#app");

// app.config.errorHandler = (err, instance, info) => {
//     // report error to tracking services
// }
