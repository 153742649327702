<template>
  <HeaderComponent :section-class="'hero-section-home'" :show-text="true" />
  <div class="container-fluid bg-secondary d-flex justify-content-center">
    <div class="col-xxl-8 col-12">
      <div class="row flex-lg-row-reverse align-items-center py-5">
        <div class="col-10 col-sm-8 col-lg-6">
          <img
            src="../assets/images/michael-computer.jpg"
            class="d-block mx-lg-auto img-fluid border shadow"
            alt="Michael"
            loading="lazy"
          />
        </div>
        <div class="col-lg-6">
          <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">
            Changing lives every day through wellness.
          </h1>
          <p class="lead">
            My name is Michael and I am a NASM Certified Personal Trainer and a
            Bioforce Certified Conditioning Coach. I am also a NASM Certified
            Nutrition Coach and I coach on health, life, and overall well-being.
          </p>
          <div class="col-12 mt-5 text-center">
            <router-link
              to="/bio"
              type="button"
              class="btn btn-dark text-primary btn-lg m-2"
            >
              Full Bio
            </router-link>
            <router-link
              to="/vision"
              type="button"
              class="btn btn-dark text-primary btn-lg"
            >
              My Vision
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="what-i-offer" class="container">
    <h2 class="pb-2 border-bottom">What I Offer</h2>
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-4">
      <div class="feature col">
        <div
          class="feature-icon d-inline-flex align-items-center justify-content-center bg-dark bg-gradient fs-2 mb-3"
        >
          <i class="bi bi-clipboard-check text-primary"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis">Personal Training</h3>
        <p>
          I am a NASM Certified Personal Trainer and a Bioforce Certified
          Conditioning Coach. I specialize in strength and conditioning. I
          incorporate kettlebell, holistic and functional training. I work on
          core and abdominal development and strengthening.
          <span id="collapsePersonalTraining" class="collapse"
            >I coach on how to use dynamic energy control during training
            sessions to maximize endurance and performance. I advise on
            regeneration and recovery training. I program HIIT techniques and
            post-workout cooldowns for a total body training experience.</span
          >
        </p>
        <a
          data-bs-toggle="collapse"
          href="#collapsePersonalTraining"
          role="button"
          aria-expanded="false"
          aria-controls="collapsePersonalTraining"
          class="icon-link"
        >
          Read more / collapse
          <i class="bi bi-chevron-right"></i>
        </a>
      </div>
      <div class="feature col">
        <div
          class="feature-icon d-inline-flex align-items-center justify-content-center bg-dark bg-gradient fs-2 mb-3"
        >
          <i class="bi bi-droplet text-primary"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis">Nutrition Coaching</h3>
        <p>
          As a NASM Certified Nutrition Coach, I provide nutrition guidance on
          how to incorporate balanced eating into your lifestyle. I set
          nutrition goals, implement action plans and support you with your
          change to healthy eating behaviors.
        </p>
      </div>
      <div class="feature col">
        <div
          class="feature-icon d-inline-flex align-items-center justify-content-center bg-dark bg-gradient fs-2 mb-3"
        >
          <i class="bi bi-activity text-primary"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis">Health Coaching</h3>
        <p>
          As a supportive and motivational health coach, I help you make
          behavioral, nutritional and other changes in order to promote better
          living. I coach on mindset motivation, goal setting, accountability,
          mind and body connection and overall positive lifestyle health and
          wellness.
        </p>
      </div>
      <div class="feature col">
        <div
          class="feature-icon d-inline-flex align-items-center justify-content-center bg-dark bg-gradient fs-2 mb-3"
        >
          <i class="bi bi-brightness-alt-high text-primary"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis">Mindset Coaching</h3>
        <p>
          I believe mindset is everything. As your mindset mentor, I will
          support you in developing a proactive mindset as you face changes or
          challenges in your life. I coach on developing a growth mindset, help
          you break through barriers, implement mindset goals, take action, and
          make growth a daily habit.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <router-link to="/programs" class="btn btn-dark text-primary">
          See all programs
        </router-link>
      </div>
    </div>
  </div>

  <div class="container mb-4">
    <h2 class="pb-2 border-bottom">Testimonials</h2>
    <div class="row g-4 py-5">
      <div id="testimonialCarousel" class="carousel slide">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Roxanne D"
          ></button>
          <button
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide-to="1"
            aria-label="Kristi P"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col-xs-2 col-md-1">
                <img
                  src="@/assets/images/quote-marks.png"
                  class="img quote-mark-testimonials"
                />
              </div>
              <div class="col-xs-10 col-md-11">
                <p>
                  I did the 12 week program with Michael and it was amazing!
                  After a call to assess my needs Michael tailored a total body
                  workout that was fun and had variety, each week was different
                  which I loved! I did the workouts on my own with a weekly
                  check in from Michael to see how I was doing, and received fun
                  videos during the week to keep me going! Michael was there
                  whenever I had a question about an exercise, and even did
                  video demos! I cannot recommend Michael highly enough. I now
                  have a program that I can carry with me and keep the momentum
                  going and stay on track.
                </p>
                <p class="float-end"><i>- Roxanne D</i></p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-xs-2 col-md-1">
                <img
                  src="@/assets/images/quote-marks.png"
                  class="img quote-mark-testimonials"
                />
              </div>
              <div class="col-xs-10 col-md-11">
                <p>
                  I contacted Michael because I was very frustrated with myself,
                  having troubles staying motivated with exercise, and
                  frustration wanting to exercise but experiencing pain and
                  injuries after. Now I am motivated to exercise and to exercise
                  regularly. I have an exercise library that works for my goals
                  of what I am targeting and also to keep me motivated. Michael
                  is supportive in all aspects of his mentoring with exercise,
                  nutrition, and personal empowerment. He's taught me to
                  prioritize ME! He's always available to answer questions and
                  he's supportive of all of my wins like getting into a smaller
                  pair of jeans! Because of Michael, my thoughts of fitness have
                  changed positively. It's now a part of my every day. Michael
                  pays attention to the details of his clients. Michael shows me
                  the exercise prior to trying it and then mirrors me so I can
                  learn the movement and be able to do it independently at home.
                  Michael's attention to his clients is unparalleled.
                </p>
                <p class="float-end"><i>- Kristi P</i></p>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <router-link to="/testimonials" class="btn btn-dark text-primary">
          See all testimonials
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "./core/Header.vue";

export default {
  name: "HomeComponent",
  components: {
    HeaderComponent,
  },
};
</script>
