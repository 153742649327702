<template>
  <HeaderComponent
    :section-class="'hero-section-contact'"
    :show-text="false"
  ></HeaderComponent>
  <div class="container">
    <div class="row pt-5">
      <div class="col-lg-6 col-12">
        <CardComponent :title="'Contacting Me'">
          <p>
            * Double check that your email address, name, and phone number is
            correct
          </p>
          <p>* Leave a message with your general inquiry</p>
          <br />
          <strong
            >I will get back to you as soon as possible! Thank you for
            contacting me!</strong
          >
        </CardComponent>
      </div>
      <div class="col-lg-6 col-12">
        <ContactFormComponent />
      </div>
    </div>
  </div>
</template>

<script>
import ContactFormComponent from "./shared/ContactForm.vue";
import HeaderComponent from "./core/Header.vue";
import CardComponent from "./core/Card.vue";

export default {
  name: "ContactComponent",
  components: {
    ContactFormComponent,
    HeaderComponent,
    CardComponent,
  },
};
</script>
