<template>
  <nav class="navbar navbar-expand-lg navbar-scroll nav-menu sticky-top">
    <div class="container">
      <a v-if="!isMobile" href="/" class="navbar-brand">
        <img class="logo nav-logo" src="@/assets/images/logo.png" />
      </a>
      <a v-if="isMobile" href="/" class="navbar-brand">
        <img class="logo" src="@/assets/images/logo-sm.png" />
      </a>
      <button
        class="navbar-toggler collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navBarLinks"
        aria-controls="navBarLinks"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <span class="icon-bar top-bar"></span>
        <span class="icon-bar middle-bar"></span>
        <span class="icon-bar bottom-bar"></span>
      </button>
      <div id="navBarLinks" class="collapse navbar-collapse">
        <ul class="navbar-nav nav-pills ms-auto text-center">
          <li class="nav-item">
            <router-link to="/vision" class="nav-link nav-text">
              LyfeScheme Vision
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/programs" class="nav-link nav-text">
              Programs
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/bio" class="nav-link nav-text"> Bio </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/testimonials" class="nav-link nav-text">
              Testimonials
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact" class="nav-link nav-text">
              Contact
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavbarComponent",
  props: {
    isMobile: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
