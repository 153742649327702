import { HTTPBaseService } from "./base/HTTPService.js";
import config from "./base/config";

export class EmailService extends HTTPBaseService {
  static classInstance = null;

  constructor(token) {
    super(`${config.hostName}/api`, token);
  }

  static getInstance(token) {
    if (!this.classInstance) {
      this.classInstance = new EmailService(token);
    }

    return this.classInstance;
  }

  // Still doesn't error handle correctly
  static async sendEmail(requestOptions) {
    try {
      const response = await this.getInstance("").instance.post(
        "/email",
        requestOptions
      );

      return response;
    } catch {
      return null;
    }
  }
}
