<template>
  <Transition>
    <div
      v-show="value"
      class="modal-container modal fade show"
      tabindex="-1"
      aria-labelledby="modalLabel"
      aria-modal="true"
      role="dialog"
      @click="close()"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-xl"
        role="document"
        @click.stop=""
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="modalLabel" class="modal-title">Image View</h5>
            <button
              type="button"
              class="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              @click="close()"
            ></button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-dark text-primary"
              data-dismiss="modal"
              @click="close()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "ModalComponent",
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    close() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
